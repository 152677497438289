export const speakerList = [
    {
        name: "Tracey Schmitt",
        title: "Inspirational Speaker and Author",
        about: "Oprah Magazine shared her story as Quest for the Gold World Cup Sailor sailing against able bodied men. Now in 2024 a #1 International Award-Winning Leader & Speaker with stars like Jane Fonda and Dr. Phil in over 40+ countries, a TV and Podcast HOST, #1 Bestselling Author of the book ‘Unstoppable You’, whose forward is written by Cup of Chicken Soup for the Soul, and Canada’s Hall of Fame inductee who has over 100 million viral video views, BEd, and MBA. Oh, and... she was the 1st four-way amputee, born without hands or legs, to climb the Himalaya Mountains, sail, scuba dive and bronze medalist alpine skier. Clearly our expert on DisArming Lim(b)iting Beliefs."
    },
    {
        name: "Shania Bhopa",
        title: "CIHR Vanier Scholar and a PhD Candidate",
        about: "Shania is a CIHR Vanier Scholar and a PhD candidate. She is the founder of the national NGO, the Canadian Courage Project, and the author of \"Gurk the Gut\" and \"Bianca the Brain.\" As an international speaker and award-winning entrepreneur, Shania has made significant contributions in the field of women's health. She served as the Health Sciences Valedictorian in 2022 and is an angel investor focusing on consumer products that are good for the body and the planet. Most recently, Shania built a social media community, WellbyShania, which has garnered an audience of over 200k women worldwide. Shania is here to empower multifaceted women around the world with the tools, systems, and community to do it all..well."
    },
    {
        name: "Rachel Almaw",
        title: "MSc student at the University of Waterloo’s Faculty of Health",
        about: "Rachel Almaw is an MSc student at the University of Waterloo’s Faculty of Health. Her research focuses on the pain experience of those living with osteoarthritis, with a particular interest in the intersection of race and health. She has conducted research on a national and international scale, giving her a broad perspective on health disparities. Rachel is passionate about advocacy, striving to bring awareness and change to systemic issues in healthcare. She is dedicated to making a meaningful impact by advocating for a change in Canada’s approach to data collection and research to create a more equitable healthcare system. Her goal is to bridge the gap in healthcare access and ensure that every individual receives the care they deserve."
    },
    {
        name: "Michelle Grocholsky",
        title: "CEO + FOUNDER of Empowered EDI",
        about: "Michelle’s life and work are driven by the philosophy that we must lift as we rise. An award-winning equity strategist with global distinctions in the areas of innovation in engagement and inclusion, Michelle has devoted her life’s work to understanding and addressing the root causes of bias and discrimination across our workplaces. As Empowered’s CEO, she is responsible for building the company’s global equity, diversity and inclusion (EDI) brand and for realizing Empowered’s vision to make organizations more equitable by improving the way people decisions are made and human resources practices operate. Michelle believes that when organizations move beyond talk and symbolic gestures to build a systemic, strategic approach to EDI, they can build workplaces and cultures where all people can prosper and thrive. Michelle is a much-sought speaker on topics related to equity, diversity and inclusion and is regularly engaged by universities, professional associations and employers the word-over for her thought-provoking perspectives on EDI. Her work has been recognized with a Catalyst Canada Award, a European Diversity Award for Team of the Year, the Engage Award for Voice of the Employee and a Best Companies Award for Innovation in Engagement. In 2023, Michelle was named one of Canada’s Most Powerful Women: Top 100 and was honoured as a Top 40 Under 40 award winner. In 2024, Empowered received a prestigious CWE Industry award, powered by Google, in the category of Gender Equality in the Workplace. She holds a Masters of Industrial Organizational Psychology from the University of Waterloo with a specialization in equity, diversity and inclusion and is a certified Coach with the International Coaching Federation. As the loving mom of two littles, Michelle is determined to build a better and more accepting world for future generations. Her favourite read is ‘Biased’ by Jennifer L. Eberhardt."
    },
    {
        name: "Meti Basiri",
        title: "Co-Founder & CEO at ApplyBoard",
        about: "Meti Basiri is a seasoned relationship-builder who has a strong appetite for driving positive change. As a former international student himself, Meti leans on his experience to help his teams support students along their educational journey. Meti believes effective and clear lines of communication are a cornerstone of the continuous innovation he strives to create. Since co-founding ApplyBoard in 2015 with his brothers, Meti has been instrumental in building longstanding partnerships with over 1,500 of the top educational institutions across Canada, the United States, the United Kingdom, Australia, and Ireland, has helped over 1 million students achieve their study abroad dreams. As CEO, Meti is charting a path for ApplyBoard to strengthen and improve international student mobility by breaking down barriers in new, innovative ways that will last generations. He does this with a focus on the future and aligning internal talent with external operational execution on a global scale."
    },
    {
        name: "Krysta Traianovski",
        title: "Associate Director of Founder Development at Velocity",
        about: "Krysta is currently the Associate Director of Founder Development at Velocity, the University of Waterloo's flagship incubator. Her mission is to develop students into founders by designing engaging programs and opportunities that move founders further, faster. Previously, Krysta supported deep tech teams at the University of Waterloo campus with their business and technical development through Velocity Science. Prior to her role at Velocity, Krysta worked for a seed-stage health tech startup and founded a health tech company \"BrightGuide\" which was specialized in dementia care, which won “Best Idea for Diagnosed Individuals” at DementiaHack in 2017. Krysta holds an Honours BSc in Chemistry and a Master’s degree in Business, Entrepreneurship, and Technology (MBET) from the University of Waterloo."
    },
    {
        name: "Joel Blit",
        title: "Professor of Economics at the University of Waterloo ",
        about: "Dr. Joel Blit is a Professor of Economics at the University of Waterloo, where he Chairs the Council on Innovation Policy, and a Senior Fellow at the Centre for International Governance Innovation. He is a leading expert on innovation policy and strategy, studying, among other things, the role of multi-location firms in promoting the flow of knowledge across geographical space, the effect of patents on innovation, whether skilled immigration fosters innovation, and automation and the future of work. His work has featured in numerous national and international media outlets including The Globe and Mail, the CBC and The Economist. Joel leverages his expertise to advise policymakers and industry leaders on the transformative potential of artificial intelligence. He has advised CEOs, government Ministers and Deputy Ministers, and the G7 representatives, among others. His innovative framework for thinking about technological disruption has been adopted by a multi-billion dollar financial firm as the foundation of its AI strategy. A sought-after speaker, facilitator, and consultant, Dr. Blit regularly delivers compelling talks, productive workshops, and invaluable consulting services to global organizations and government. Joël is fluent in English, French and Spanish.  He lives within walking distance of the University of Waterloo with his wife and four kids."
    },
    {
        name: "Dr. Marjorie Dixon",
        title: "Obstetrics and Gynecology Specialist",
        about: "Dr. Marjorie Dixon is an Obstetrics and Gynecology specialist with an Accredited Fellowship in Reproductive Endocrinology and Infertility. In 2016, Dr. Dixon founded Anova Fertility & Reproductive Health, the leading fertility and IVF center in Canada. As Founder, CEO, and Medical Director, she propelled Anova to the forefront of innovative reproductive care. She was honored with the Mathias Gysler Award in 2022 for improving reproductive care, she was featured in the Globe & Mail's Quantum Shift Class of 2019 and received the RBC Canadian Women of Influence Momentum Award in 2018. Dr. Dixon has been recognized as one of Canada’s Top 100 Most Powerful Women and is a recent recipient of the Women of Inspiration award. Dr Dixon has been featured in Time magazine, Vanity Fair, Flare, Chatelaine, The National Post, The Financial Post, Fertility and Sterility, Human Reproduction and is known for her monthly appearances on Cityline, elevating the discussion about women's reproductive and fertility health. Dr. Marjorie Dixon is a McGill University School of Medicine graduate, completed Obstetrics and Gynecology training at the University of Toronto and specialized in Reproductive Endocrinology and Infertility at the University of Vermont. She is a member of Sunnybrook Health Sciences Center's Department of Obstetrics and Gynecology and an Assistant Professor at the University of Toronto. Dr. Dixon’s transformative leadership in reproductive medicine has positioned her as a respected figure and standard of excellence within the industry."
    },
    {
        name: "Julia Nuguid",
        title: "Global Business and Digital Arts Student with a Minor in Computing ",
        about: "Julia is a 3rd year Global Business and Digital Arts student pursuing a minor in Computing. Exploring a background in user experience design, her work is driven by community and human-centered solutions. When she's not in school, Julia loves playing piano, scrolling aimlessly on Tik Tok and sending word hunt to her entire iMessage contact list."
    },
    {
        name: "Matthew Kee",
        title: "MC",
        about: "Matthew Kee is a Toronto-born writer, poet, and engineer. He grew up watching TED Talks and poetry videos on YouTube, and developed a love of performance and storytelling. Matthew started writing poetry in high school and has since performed on stages across Canada — even competing at a national level in 2023. Matthew primarily writes about identity and community, and will often research plants, animals, or scientific phenomena to find the perfect metaphor. Matthew was valedictorian of his graduating class at the University of Waterloo, where he graduated from Systems Design Engineering in 2024. He now works as a Software Engineer at Outschool, an online learning platform that offers classes to more than 1 million kids around the world. "
    },
]